class itemPage extends Component {

    static name() {
        return "itemPage";
    }

    static componentName() {
        return "itemPage";
    }

    getMethods(){
        return {
            goBack: this.goBack
        };
    }

    goBack(){
        this.$router.go(-1);
    }

    getTemplate(){
        return `<div class="items-view view oo-ecommerce">
                <headerSectionComponent :items_in_car="$store.items_in_car" ></headerSectionComponent>
                <div :class="classContainer">
                    <div class="section-main">
                        <div class="row">
                            <div class="col-sm-3 col-md-2 col-xs-12 mb-2 mb-sm-0 p-2 filters-section">
                              <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                            </div>
                            <div class="col-sm-9 col-md-10">
                                <itemsBreakcrumb/>
                                <singleItemComponent :item="$store.getters.getItemByCode($route.params.id)" :container="false"></singleItemComponent>
                          </div>
                        </div>
                    </div>
                </div>
                <footerSectionComponent></footerSectionComponent>
            </div>`;
    }
}

itemPage.registerComponent();


/*Vue.component('itemViewComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template:`<div class="items-view view oo-ecommerce">
                <headerSectionComponent :items_in_car="$store.items_in_car" ></headerSectionComponent>
                <div class="container-fluid">
                    <div class="section-main">
                        <div class="row">
                            <div class="col-sm-3 col-md-2 col-xs-12 mb-2 mb-sm-0 p-2 filters-section">
                              <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                            </div>
                            <div class="col-sm-9 col-md-10">
                                <itemsBreakcrumb/>
                                <singleItemComponent :item="$store.getters.getItemByCode($route.params.id)" :container="false"></singleItemComponent>
                          </div>
                        </div>
                    </div>
                </div>
                <footerSectionComponent></footerSectionComponent>
            </div>`
});
*/